import reducer from "../reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";

const gate_closed = localStorage.getItem('gate_closed');


if ( !gate_closed && gate_closed !== 'false' ) {

    localStorage.setItem('gate_closed', 'true');

}


const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;


