import React, { Component } from 'react';
import './Tooltip.css';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
const appRoot = document.getElementById('root');




class Tooltip extends Component {

    render() {

        return (

            <>

                { ReactDOM.createPortal(

                    <>

                        {(this.props.warningMode)

                            ?

                                <ReactTooltip
                                    className="tooltip-container warning-tooltip"
                                    id={this.props.tooltipId}
                                    border={true}
                                    borderColor="red"
                                    type={this.props.tooltipType}
                                    effect={this.props.tooltipEffect}
                                />

                            :

                                <ReactTooltip
                                    className="tooltip-container"
                                    id={this.props.tooltipId}
                                    border={true}
                                    borderColor="#91ADC1"
                                    type={this.props.tooltipType}
                                    effect={this.props.tooltipEffect}
                                />
                        }

                    </> , appRoot) }

                { ( this.props.tooltipEffect !== 'float' ) ?

                    <span
                        data-for={this.props.tooltipId}
                        data-tip={this.props.tooltipContent}
                        className="icon-info tooltip-icon"
                        style={{
                            fontSize: this.props.tooltipIconSize,
                            top: this.props.tooltipIconTopPosition,
                            marginLeft: this.props.tooltipIconOffset
                        }}
                    ></span>

                : null }

            </>

        )

    }


}

export default Tooltip;
