import {
    SAVE_INSURANCE_PLAN_DATA_FOR_GET_PLAN
} from "../actions/actions";


//
// let initialState = {
//     plan_data: {
//         "plan_id": "A11", // виведи це десь в якесь любе мета поле типу <div id="plan_A11" щоб було зручно дебажити інспектором просто
//         "insurance_company": "April",
//         "coverage_types_included": "H+RC+OD+M", //this one will be generated on the front-end along with plan_id and insurance company
//         "guarantee_level_EA_displayed": "VIP", //this one on the front-end is used only for filtering and displaying the ribbon,
//
//         //the following ones are used to build the options inputs on the card, TODO: write the function to create the inputs
//         "deductible_options" : {
//             "type": "AVAILABLE",
//             "values": [0,500,1000,2500,5000]
//         },
//         "coinsurance_options": {
//             "type": "AVAILABLE",
//             "values": [100, 90, 80]
//         },
//         "selected_radio_option": "deductible",
//
//
//         //всі ці поля нижче тебе не хвилюють поки що,
//         //їх треба буде тримати прив'язаними до цієї плашки і передати в функцію калькуляції в той момент коли пора рахувати
//         //ціну яка відобразиться в полі "premium"
//
//         "destination_zone": "Zone 3",
//         "spreadsheet_rows": [    //this one will be added upon request to the back-end
//             {
//                 "Age": 35,
//                 "Tarif ANNUEL FDS TTC": 1024
//             },
//             {
//                 "Age": 24,
//                 "Tarif ANNUEL FDS TTC": 832
//             }
//         ],
//
//         //міша певно додасть сюди поля для вибраних значень coinsurance і deductible з інпутів - потрібно домовитись за формат
//         //TODO: add liability and repatriation
//     }
// };


let initialState = {
    plan_data: null
};




export function get_plan_reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_INSURANCE_PLAN_DATA_FOR_GET_PLAN :

            return {
                ...state,
                plan_data: action.payload
            };

        default:

            return state
    }
}
