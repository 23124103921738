import React, { Component } from 'react';
import './ThankYouPage.css';
import {Col, Container, Row} from "react-bootstrap";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import waveBg from "../../../assets/images/wave-bg-new.svg";
import thankYouImg from "../../../assets/images/thank-you.png";
import { embedHubSpotInitScripts } from "../../../global-app-elements/global_helper_functions";
import {GAevent, pageViewGA} from "../../../global-app-elements/ga-functions";









class ThankYouPage extends Component {


    constructor(props) {

        window.scrollTo(0, 0);

        super(props);

        this.state = {
            pageContentType: 'merci'
        };

        this.handleEntreRappele = this.handleEntreRappele.bind(this);

    }


    componentDidMount() {

        // TRIGGER PAGE VIEW FOR GOOGLE ANALYTICS
        pageViewGA('thank-you');
        GAevent('comparateur get-plan filled', 'comparateur');

    }


    handleEntreRappele() {

        this.setState({ pageContentType: 'entre-rappele' });

    }


    renderThankYouPageContent() {

        switch ( this.state.pageContentType ) {
            case 'merci':
                return (

                    <>
                        <div className="thankYouPageBlock">
                            <div className="thankYouPageIcon"><img src={thankYouImg} alt="Expat Assurance"/></div>
                            <div className="thankYouPageTitle">Merci !</div>
                            <div className="thankYouPageDescription">
                                Un de nos conseillers va vous recontacter sous 24 heures les jours ouvrés.
                                Si vous préférez choisir vous-même l'heure de votre rendez-vous téléphonique, <button onClick={this.handleEntreRappele}>cliquez ici</button>
                            </div>
                            <div className="thankYouPageButton">
                                <Link to={'/'}><button className="mainButtonStyle">Revenir à l'accueil</button></Link>
                            </div>
                        </div>
                    </>

                );

            case 'entre-rappele':
                return (

                    <>
                        <div className="thankYouPageÊtreRappeléBlock">

                            <div
                                className="meetings-iframe-container"
                                data-src="https://app.hubspot.com/meetings/pauline43/expat-assurance?embed=true"></div>

                            <div className="thankYouPageButton">
                                <Link to={'/'}><button className="mainButtonStyle">Revenir à l'accueil</button></Link>
                            </div>

                            { embedHubSpotInitScripts() }

                        </div>
                    </>

                );

            default:

                return null

        }


    }




    render() {




        return (


            <div className="thankYouPage">

                <Container className="smallContainer thankYouPageContainer">

                    <Row>

                        <Col xs={12}>

                            { this.renderThankYouPageContent() }

                        </Col>

                    </Row>

                    {/* BACKGROUND WAVES */}
                    <img className="waveBg1" src={waveBg} alt="Expat Assurance" />
                    <img className="waveBg2" src={waveBg} alt="Expat Assurance" />

                </Container>

            </div>

        )

    }


}

export default withRouter(connect(
    state => ({
        // insurees_data: state.main_form_data.insurees_data,
        // result_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        // selected_insurance_plan_object: state.selected_insurance_plan_data.plan_data
    }),
    dispatch => ({
        // onFetchInsurancePlans: (mainFormDataObject) => {
        //     dispatch(fetchInsurancePlans(mainFormDataObject));
        // }
    })
)(ThankYouPage));
