import {
    SAVE_INSURANCE_PLANS_DATA_FOR_COMPARE_PLANS,
    SAVE_INSURANCE_PLAN_EXTENDED_DATA_FOR_COMPARE_PLANS_FROM_API,
    CLEAR_COMPARE_PLANS_SERVER_ARRAY_BEFORE_FETCHING
} from "../actions/actions";



let initialState = {
    local_plans_data: [],
    server_plans_data: null
};




export function compare_plans_reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_INSURANCE_PLANS_DATA_FOR_COMPARE_PLANS :

            return {
                ...state,
                local_plans_data: action.payload
            };

        case SAVE_INSURANCE_PLAN_EXTENDED_DATA_FOR_COMPARE_PLANS_FROM_API :

            return {
                ...state,
                server_plans_data: action.payload
            };

        case CLEAR_COMPARE_PLANS_SERVER_ARRAY_BEFORE_FETCHING :

            return {
                ...state,
                server_plans_data: null
            };

        default:

            return state
    }
}
