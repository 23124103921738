import {
    CHANGE_PAYMENT_FREQUENCY_VALUE
} from "../actions/actions";



let initialState = {
    payment_frequency_value: 12
};




export function payment_frequency_reducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PAYMENT_FREQUENCY_VALUE :

            return {
                ...state,
                payment_frequency_value: action.payload
            };

        default:

            return state
    }
}
