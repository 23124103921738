import { combineReducers } from 'redux';
import { main_form_reducer } from "./main_form_reducer";
import { insurance_plans_reducer } from "./insurance_plans_reducer";
import { get_plan_reducer } from "./get_plan_reducer";
import { view_plan_reducer } from "./view_plan_reducer";
import { payment_frequency_reducer } from "./payment_frequency_reducer";
import { filters_options_reducer } from "./filters_options_reducer";
import { compare_plans_reducer } from "./compare_plans_reducer";
import { app_options_reducer } from "./app_options_reducer";
import { currency_options_reducer } from "./currency_options_reducer";



export default combineReducers ({
    app_options: app_options_reducer,
    main_form_data: main_form_reducer,
    result_insurance_plans: insurance_plans_reducer,
    get_plan_data: get_plan_reducer,
    view_plan_data: view_plan_reducer,
    compare_plans_data: compare_plans_reducer,
    payment_frequency: payment_frequency_reducer,
    filters_options: filters_options_reducer,
    currency_options: currency_options_reducer
});
