import {
    SAVE_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN,
    SAVE_INSURANCE_PLAN_EXTENDED_DATA_FROM_API,
    CLEAR_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN
} from "../actions/actions";



let initialState = {
    local_plan_data: null,
    server_plan_data: null
};




export function view_plan_reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN :

            return {
                ...state,
                local_plan_data: action.payload
            };

        case SAVE_INSURANCE_PLAN_EXTENDED_DATA_FROM_API :

            return {
                ...state,
                server_plan_data: action.payload
            };

        case CLEAR_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN :

            return {
                ...state,
                local_plan_data: null,
                server_plan_data: null
            };

        default:

            return state
    }
}
