import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './MainPage.css';
import waveBg from "../../../assets/images/wave-bg-new.svg";
import {Col, Container, Row, Modal} from "react-bootstrap";
import Select, { components } from 'react-select';
import {
    clearInsurancePlansArrayBeforeFetching,
    saveDataFromMainPageForm
} from "../../../actions/actions";
import { animateScroll as scroll } from 'react-scroll'
import {
    NATIONALITY_OPTIONS,
    AGE_OPTIONS,
    DESTINATION_COUNTRY_OPTIONS,
    // INSURANCE_DURATION_OPTIONS
} from "../../../global-app-elements/global_selects_options";

// import expatLogo from "../../../assets/images/expat-logo.svg";
import howItWork from "../../../assets/images/how-in-work-new.svg";
import {
    getAdultsAmount,
    textInputValidation
} from "../../../global-app-elements/global_helper_functions";
import Tooltip from "../../GlobalComponents/Tooltip/Tooltip";
import {modalViewGA, pageViewGA} from "../../../global-app-elements/ga-functions";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {fr} from 'date-fns/esm/locale';
import { parseISO } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format'








const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon-arrow-down"></span>
        </components.DropdownIndicator>
    );
};







class MainPage extends Component {


    constructor(props) {

        super(props);

        let insurees_data;

        if ( this.props.insurees_data ) {

            insurees_data = this.props.insurees_data;

        } else {

            insurees_data = {
                insuree_object: {
                    nationality: 'France',
                    age: null
                },
                dependents_array: [],
                destination_country: null,
                insurance_duration: '12>',
                insuree_has_cfe: 'NOT_KNOWN',
                date_of_subscription: moment().toDate()
            };

        }

        this.state = {
            insurees_data: insurees_data,
            form_validation_error_detected: false,
            is_modal_visible: false,
            modal_content_type: null
        };

        this.handleChangeNationality = this.handleChangeNationality.bind(this);
        this.handleChangeAge = this.handleChangeAge.bind(this);
        this.handleChangeDependentsNationality = this.handleChangeDependentsNationality.bind(this);
        this.handleChangeDependentsAge = this.handleChangeDependentsAge.bind(this);
        this.handleChangeDestinationCountry = this.handleChangeDestinationCountry.bind(this);
        this.handleChangeInsuranceDuration = this.handleChangeInsuranceDuration.bind(this);
        this.handleChangeInsureeHasCfe = this.handleChangeInsureeHasCfe.bind(this);
        this.handleGetAQuote = this.handleGetAQuote.bind(this);
        this.handleDeleteDependentItem = this.handleDeleteDependentItem.bind(this);
        this.handleAddDependentItem = this.handleAddDependentItem.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangeDateOfSubscription = this.handleChangeDateOfSubscription.bind(this);

    }


    componentDidMount() {

        if(moment(this.state.insurees_data.date_of_subscription).toDate() < moment().toDate()){

            this.setState(prevState => ({
                insurees_data: {
                    ...prevState.insurees_data,
                    date_of_subscription:  moment().toDate()
                }
            }));

        }

        // TRIGGER PAGE VIEW FOR GOOGLE ANALYTICS
        pageViewGA('/');

    }


    handleChangeNationality(selected_option) {

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_object: {
                    ...prevState.insurees_data.insuree_object,
                    nationality: selected_option.value
                }
            }
        }));

    };


    handleChangeAge(selected_option) {

        // перевірка чи не буде новий вік перевищувати допустипу кількість страших людей,
        // які вже добавлені у форму
        let adults_amount = getAdultsAmount(this.state.insurees_data, 'complex_array');
        let current_insuree_age = this.state.insurees_data.insuree_object.age;
        let selected_value;

        if ( selected_option.value > 27 ) {

            adults_amount++;

            if ( adults_amount > 2 && ( current_insuree_age === null || current_insuree_age <= 27 )) {

                this.handleShowModal('warning-message-adults-amount');
                selected_value = null;

            } else {

                selected_value = selected_option.value;

            }

        } else {

            selected_value = selected_option.value;

        }


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_object: {
                    ...prevState.insurees_data.insuree_object,
                    age: selected_value
                }
            }
        }));

    };


    handleChangeDependentsNationality(position_in_list , selected_option) {

        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array[position_in_list].nationality = selected_option.value;


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    };


    handleChangeDependentsAge(position_in_list, selected_option) {

        // перевірка чи не буде новий вік перевищувати допустипу кількість страших людей,
        // які вже добавлені у форму
        let adults_amount = getAdultsAmount(this.state.insurees_data, 'complex_array');
        let dependents_array = [...this.state.insurees_data.dependents_array];
        let current_dependent_age = dependents_array[position_in_list].age;

        if ( selected_option.value > 27 ) {

            adults_amount++;

            if ( adults_amount > 2 && ( current_dependent_age === null || current_dependent_age <= 27 )) {

                this.handleShowModal('warning-message-adults-amount');
                dependents_array[position_in_list].age = null;

            } else {

                dependents_array[position_in_list].age = selected_option.value;

            }

        } else {

            dependents_array[position_in_list].age = selected_option.value;

        }


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    };


    handleChangeDestinationCountry(selected_option) {

        let main_insuree_nationality = this.state.insurees_data.insuree_object.nationality;
        let destination_country;

        if ( selected_option.value === main_insuree_nationality ) {

            this.handleShowModal('warning-message-expatriation-country');

            destination_country = null;

        } else {

            destination_country = selected_option.value;

        }

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                destination_country: destination_country
            }
        }));

    };


    handleChangeInsuranceDuration(selected_option) {

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insurance_duration: selected_option.value
            }
        }));

    };


    handleChangeInsureeHasCfe(selected_option) {

        let input_value = selected_option.target.value;

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_has_cfe: input_value
            }
        }));

    };


    handleAddDependentItem() {

        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array.push({
            nationality: this.state.insurees_data.insuree_object.nationality,
            age: null
        });


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));


    }


    handleDeleteDependentItem(position_in_list) {


        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array.splice(position_in_list, 1);


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    }


    handleGetAQuote() {

        // TODO: Зробити толкову валідацію

        let not_valid_inputs_array = document.querySelectorAll('.mainPageFormContainer .not-valid-input');

        if ( not_valid_inputs_array.length > 0 ) {

            this.setState({ form_validation_error_detected: true });

            not_valid_inputs_array[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        } else {

            this.props.onClearInsurancePlansArrayBeforeFetching();
            this.props.onSaveDataFromMainPageForm( this.state.insurees_data );
            this.props.history.push('/insurance');

        }

    }


    handleShowModal(content_type) {

        this.setState({
            is_modal_visible: true,
            modal_content_type: content_type
        });

        // TRIGGER MODAL VIEW FOR GOOGLE ANALYTICS
        modalViewGA(content_type);

    }


    handleCloseModal() {

        this.setState({
            is_modal_visible: false,
            modal_content_type: null
        });

    }


    handleChangeDateOfSubscription(changed_value) {

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                date_of_subscription: changed_value
            }
        }));
    }


    renderDependentsList() {

        let dependents_array = this.state.insurees_data.dependents_array;
        let insurees_data = this.state.insurees_data;


        if ( dependents_array.length > 0 ) {

            return this.state.insurees_data.dependents_array.map((item, index) => {

                return (

                    <Row key={index} className="insuranceRow dependentsItemRow">
                        <Col xs={7}>
                            <div className={`inputBox ${(insurees_data.dependents_array[index].nationality) ? "is-valid-input" : "not-valid-input" }`}>
                                <Select
                                    value={ NATIONALITY_OPTIONS.filter(option => option.value === insurees_data.dependents_array[index].nationality) }
                                    defaultValue={0}
                                    components={{ DropdownIndicator }}
                                    positionInList={0}
                                    classNamePrefix="insurance-main-select"
                                    className="select_nationality"
                                    onChange={this.handleChangeDependentsNationality.bind(this, index)}
                                    options={NATIONALITY_OPTIONS}
                                />
                            </div>
                        </Col>

                        <Col xs={3}>
                            <div className={`inputBox ${(insurees_data.dependents_array[index].age) ? "is-valid-input" : "not-valid-input" }`}>
                                <Select
                                    value={ AGE_OPTIONS.filter(option => option.value === insurees_data.dependents_array[index].age) }
                                    placeholder="0"
                                    components={{ DropdownIndicator }}
                                    classNamePrefix="insurance-main-select"
                                    onChange={this.handleChangeDependentsAge.bind(this, index)}
                                    options={AGE_OPTIONS}
                                />
                            </div>
                        </Col>

                        <Col xs={2}>
                            <button className="deleteDependentButton" onClick={this.handleDeleteDependentItem.bind(this, index)}>
                                <span className="icon-trash"></span>
                            </button>
                        </Col>
                    </Row>

                );

            });

        }

    }


    renderModalContent() {

        switch ( this.state.modal_content_type ) {
            case 'warning-message-expatriation-country':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                {/*<button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}></button>*/}
                                <Modal.Title>
                                    <span className="icon-question"></span>
                                    Vous ne pouvez pas choisir le même pays de
                                    nationalité et d'expatriation.
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            <div>Vous pouvez modifier votre choix pour accéder aux résultats.</div>
                            <button className="secondaryButtonStyle" onClick={this.handleCloseModal}>Changer de critères</button>
                        </Modal.Body>
                    </Modal>

                );

            case 'warning-message-adults-amount':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                {/*<button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}></button>*/}
                                <Modal.Title>
                                    <span className="icon-question"></span>
                                    Il ne peut y avoir que 2 personnes âgées de plus de 27
                                    ans par contrat. Si vous souhaitez assurer 3 personnes
                                    de plus de 27 ans, il faudra souscrire 2 contrats différents.
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<div>Vous pouvez modifier votre choix pour accéder aux résultats.</div>*/}
                            <button className="secondaryButtonStyle" onClick={this.handleCloseModal}>Changer de critères</button>
                        </Modal.Body>
                    </Modal>

                );

            default:

                return null

        }

    }


    scrollToTop() {

        scroll.scrollToTop();

    }



    render() {

        let insurees_data = this.state.insurees_data;

        return (


            <div className="mainPage">

                <Container className="smallContainer mainPageContainer">

                    <Row>
                        <Col xs={{ span: 12, order: 'last' }} lg={{ span: 6, order: 'first' }}>

                            <div className="mainPageTitle only-desktop">
                                <h1>Comparez vos offres <span>d'assurance santé !</span></h1>
                            </div>
                            <div className="mainPageDescription only-desktop">

                                <h2>
                                    Obtenez immédiatement des tarifs exacts et personnalisés pour
                                    votre assurance santé internationale. Quelle que soit votre situation,
                                    nous avons une offre qui convient.
                                </h2>

                            </div>

                            <div className="mainPageHowItWork">
                                <img src={howItWork} alt="Expat Assurance" />
                            </div>

                            <Row>
                                <Col xs={12} className="mainPageInfoBoxContainer">
                                    <Row className="mainPageInfoBox">
                                        <Col xs={3}>
                                            <span className="icon-cup"></span>
                                        </Col>
                                        <Col xs={9}>
                                            Depuis 15 ans, <span>Expat'Assurance</span> vous accompagne au quotidien dans la gestion
                                            de votre contrat : nous assurons plus de 3200 familles à travers le monde,
                                            et travaillons avec les partenaires les plus fiables à l'international.
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <button
                                        onClick={this.scrollToTop}
                                        className="scrollToFormButton secondaryButtonStyle only-mobile">
                                        Voir les tarifs</button>
                                </Col>
                            </Row>

                        </Col>
                        <Col className="testingVersionForMainForm" xs={{ span: 12, order: 'first' }} lg={{ span: 6, order: 'last' }}>


                            <div className="mainPageMobileHead only-mobile">
                                <div className="mainPageTitle">
                                    <h1>Comparez vos offres <span>d'assurance santé !</span></h1>
                                </div>
                                {/*<div className="mainPageDescription">*/}

                                {/*    <h2>*/}
                                {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                                {/*        sed do eiusmod tempor incididunt.*/}
                                {/*    </h2>*/}

                                {/*</div>*/}
                            </div>

                            <div className={`mainPageFormContainer ${(this.state.form_validation_error_detected) ? "form-validation-error" : null }`}>

                                <div className="mainPageFormTitle">Vos informations</div>

                                <Row className="insuranceRow">

                                    <Col xs={7}>
                                        <div className={`inputBox ${(textInputValidation(insurees_data.insuree_object.nationality)) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label>Pays de nationalité<span className="red">*</span></label>
                                            <Select
                                                value={ NATIONALITY_OPTIONS.filter(option => option.value === insurees_data.insuree_object.nationality) }
                                                components={{ DropdownIndicator }}
                                                positionInList={0}
                                                placeholder="Sélectionner"
                                                classNamePrefix="insurance-main-select"
                                                className="select_nationality"
                                                onChange={this.handleChangeNationality}
                                                options={NATIONALITY_OPTIONS}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={3}>
                                        <div className={`inputBox ${(insurees_data.insuree_object.age) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label>Âge<span className="red">*</span></label>
                                            <Select
                                                value={ AGE_OPTIONS.filter(option => option.value === insurees_data.insuree_object.age) }
                                                placeholder="0"
                                                components={{ DropdownIndicator }}
                                                classNamePrefix="insurance-main-select"
                                                onChange={this.handleChangeAge}
                                                options={AGE_OPTIONS}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={2}>
                                        <button className="addDependentsButton" onClick={this.handleAddDependentItem}>
                                            <span className="icon-user"></span> Ajout
                                        </button>
                                    </Col>

                                </Row>

                                <div className="dependentsContainer">

                                    { this.renderDependentsList() }

                                </div>

                                <Row>

                                    <Col xs={12} className="expatriation">
                                        <div className={`inputBox ${(insurees_data.destination_country) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label>Pays d'expatriation<span className="red">*</span></label>
                                            <Select
                                                value={ DESTINATION_COUNTRY_OPTIONS.filter(option => option.value === insurees_data.destination_country) }
                                                components={{ DropdownIndicator }}
                                                placeholder="Sélectionner le pays d'expatriation"
                                                classNamePrefix="insurance-main-select"
                                                onChange={this.handleChangeDestinationCountry}
                                                options={DESTINATION_COUNTRY_OPTIONS}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                {/*<Row>*/}

                                {/*    <Col xs={12}>*/}
                                {/*        <div className={`inputBox ${(insurees_data.insurance_duration) ? "is-valid-input" : "not-valid-input" }`}>*/}
                                {/*            <label>Quelle sera la durée de votre séjour ?</label>*/}
                                {/*            <Select*/}
                                {/*                value={ INSURANCE_DURATION_OPTIONS.filter(option => option.value === insurees_data.insurance_duration) }*/}
                                {/*                components={{ DropdownIndicator }}*/}
                                {/*                classNamePrefix="insurance-main-select"*/}
                                {/*                onChange={this.handleChangeInsuranceDuration}*/}
                                {/*                options={INSURANCE_DURATION_OPTIONS}*/}
                                {/*                isSearchable={false}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}

                                {/*</Row>*/}

                                <Row>

                                    <Col xs={12}>
                                        <div className="inputBox radioInputBox">
                                            <label>
                                                Serez-vous couvert·e par la CFE ?
                                                <Tooltip
                                                    tooltipId="main_cfe"
                                                    tooltipType="light"
                                                    tooltipEffect="solid"
                                                    tooltipIconSize={16}
                                                    tooltipIconTopPosition={2}
                                                    tooltipIconOffset={5}
                                                    tooltipContent="
                                                    Si vous choisissez cette option, nous vous donnerons les tarifs en complément de la Caisse des Français de l'Étranger."
                                                />
                                            </label>

                                            <Row className="radioBlockRow">

                                                <Col xs={3}>
                                                    <input
                                                        type="radio"
                                                        id="insuree_has_cfe_yes"
                                                        value="YES"
                                                        name="cfe_radio"
                                                        defaultChecked={(insurees_data.insuree_has_cfe === "YES") ? true : false}
                                                        onChange={this.handleChangeInsureeHasCfe}
                                                    />
                                                    <label htmlFor="insuree_has_cfe_yes">Oui</label>
                                                </Col>
                                                <Col xs={3} className="mediumRadioCol">
                                                    <input
                                                        type="radio"
                                                        id="insuree_has_cfe_no"
                                                        value="NO"
                                                        name="cfe_radio"
                                                        defaultChecked={(insurees_data.insuree_has_cfe === "NO") ? true : false}
                                                        onChange={this.handleChangeInsureeHasCfe}
                                                    />
                                                    <label htmlFor="insuree_has_cfe_no">Non</label>
                                                </Col>
                                                <Col xs={6}>
                                                    <input
                                                        type="radio"
                                                        id="insuree_has_cfe_not_know"
                                                        value="NOT_KNOWN"
                                                        name="cfe_radio"
                                                        defaultChecked={(insurees_data.insuree_has_cfe === "NOT_KNOWN") ? true : false}
                                                        onChange={this.handleChangeInsureeHasCfe}
                                                    />
                                                    <label htmlFor="insuree_has_cfe_not_know">Je ne sais pas</label>
                                                </Col>

                                            </Row>
                                            <Row>

                                                <Col xs={12} className="dateInputCol">
                                                    <div className={`inputBox`}>
                                                        <label>Date d'effet souhaitée<span className="red">*</span></label>
                                                        <DatePicker
                                                            minDate={moment().toDate()}
                                                            calendarClassName="insurance-main-datepicker"
                                                            locale={fr}
                                                            dateFormat="dd/MM/yyyy"
                                                            onFocus={(e) => e.target.readOnly = true}
                                                            placeholderText={moment(new Date()).format("DD/MM/YYYY")}
                                                            selected={this.state.insurees_data.date_of_subscription ?  moment(this.state.insurees_data.date_of_subscription).toDate() : moment().toDate()}
                                                            onChange={this.handleChangeDateOfSubscription}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>

                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <button className="mainButtonStyle handleGetAQuoteButton" onClick={this.handleGetAQuote}>
                                            Voir les tarifs <span className="icon-big-arrow-right"></span>
                                        </button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="legalCol">
                                        <p>
                                            Les données collectées par Expat International Care sont nécessaires afin de vous présenter des produits d’assurance adaptés à votre situation, et faciliter la souscription de votre contrat d’assurance. En cas d’accord de votre part, vos données sont également collectées à des fins de prospection commerciale. Les données suivies d’un (*) sont obligatoires. A défaut, nous ne pourrons valablement traiter votre demande. Pour en savoir plus sur le traitement de vos données et sur vos droits, consultez notre <a href="https://expat-assurance.com/fr/politique-protection-donnees/" target="_blank">Politique en matière de protection des données personnelles</a>.
                                        </p>
                                    </Col>
                                </Row>

                            </div>

                        </Col>
                    </Row>

                    {/* BACKGROUND WAVES */}
                    <img className="waveBg1" src={waveBg} alt="Expat Assurance" />
                    <img className="waveBg2" src={waveBg} alt="Expat Assurance" />

                    <div className="postContainer">

                        <h1>L'importance d'utiliser un comparateur pour choisir son assurance expatrié</h1>

                        <p>Une des démarches essentielles de la préparation d’une expatriation est de prendre soin de sa
                            couverture santé. Il existe une multitude de possibilités et le meilleur choix dépend de la
                            situation de chacun.</p>

                        <p>Bien choisir sa couverture santé nécessite de réaliser un comparatif des offres disponibles
                            et pour cela, l'utilisation d'un <b>comparateur d'assurances expatrié</b> est la meilleure
                            solution.</p>

                        <p>Explications.</p>

                        <h2>Assurance pour expatrié : bien connaître ses besoins avant de procéder au comparatif</h2>

                        <p>Pour choisir la meilleure assurance santé et savoir quels éléments comparer, un expatrié doit
                            connaître ses besoins et prendre en compte quelques points essentiels.</p>

                        <h3>Des systèmes de santé très différents à travers le monde</h3>

                        <p><b>Connaître le système de santé, les tarifs ainsi que la qualité des soins du pays
                            d’expatriation est déterminant pour choisir la meilleure assurance.</b></p>

                        <p>Certains pays sont connus pour des tarifs très élevés ou pour un système public de santé
                            défaillant nécessitant le recours systématique à des établissements privés. Ces derniers
                            sont plus chers et peuvent exiger une garantie de paiement comme la carte d’assuré avant
                            toute prise en charge.</p>

                        <p>Dans certains cas, un régime obligatoire peut s’imposer à tous les résidents et salariés du
                            pays. Dans ce cas, il convient d’étudier les garanties offertes et éventuellement envisager
                            de souscrire une complémentaire afin d'être correctement couvert.</p>

                        <h3>Choisir la meilleure assurance expatrié selon ses besoins</h3>

                        <p>Pour choisir la meilleure assurance, un expatrié doit également <b>tenir compte de sa
                            situation individuelle :</b></p>

                        <ul>
                            <li><b>Antécédents médicaux ou maladie chronique</b> susceptibles de générer une exclusion
                                de garanties
                            </li>
                            <li><b>Demande de visas ou d’inscription dans un établissement scolaire nécessitant la
                                souscription d’une assurance selon des critères précis</b></li>
                            <li><b>Prise en charge de l’assurance maladie par l’entreprise du salarié expatrié</b>, mais
                                absence de certaines garanties comme le rapatriement.
                            </li>
                        </ul>

                        <p>Enfin, la <b>durée de l’expatriation a également une incidence sur le choix de la meilleure
                            assurance pour un expatrié.</b> Des séjours fréquents en France, un retour anticipé, la
                            retraite ou une maternité à venir ne concernent pas tous les expatriés et ne sont pas tous
                            pris en charge par les assurances.</p>

                        <h2>Élément clé à comparer : les garanties de l’assurance expatrié</h2>

                        <p>Une fois que l’expatrié connaît ses besoins en matière d’assurance, il doit savoir sur quels
                            éléments se baser pour comparer les assurances pour expatriés.</p>

                        <h3>La couverture médicale</h3>

                        <p>Une bonne assurance expatrié doit <b>protéger l'assuré partout dans le monde</b> et pas
                            seulement dans le pays d’expatriation.</p>

                        <p>La prise en charge des soins reçus lors de séjours en France est prévue dans la plupart des
                            cas, mais doit être vérifiée. Pour les expatriés bénéficiant d’une couverture santé locale,
                            la <a href="https://expat-assurance.com/fr/assurance-sante/caisse-francais-etranger/">Caisse
                                des Français de l'Étranger (CFE)</a> propose un contrat couvrant exclusivement les soins
                            reçus en France.</p>

                        <p>De nombreuses compagnies font bénéficier leurs assurés de partenariat avec de bons réseaux de
                            soins locaux.</p>

                        <p>Parmi les avantages proposés, il faut toujours vérifier la <b>présence du tiers-payant
                            hospitalier</b>. Il s’agit, en effet, du poste de dépense le plus coûteux en matière de
                            santé. Il vaut donc mieux être certain de ne pas avoir à avancer de somme conséquente en cas
                            d’imprévu.</p>

                        <h3>Exclusions de garanties et risques non couverts</h3>

                        <p>Les éventuelles <b>exclusions de garantie ou surprimes doivent être examinées</b> dans le
                            comparatif des offres d’assurance pour expatrié.</p>

                        <p>La CFE ne fait pas remplir de <b>questionnaire de santé à l’inscription</b>. Contrairement
                            aux autres compagnies, celle-ci ne peut refuser une adhésion ni modifier ses cotisations ou
                            garanties selon l’état de santé de l’assuré. Cela est un avantage considérable en cas de
                            préexistences médicales.</p>

                        <p>Autre point important : <b>le rapatriement</b> qui coûte très cher et qui peut,
                            malheureusement, concerner n’importe qui. La CFE ne propose cette option que pour les moins
                            de 30 ans célibataires. Le rapatriement est en revanche inclus dans les contrats <a
                                href="https://expat-assurance.com/fr/assurance-sante/assurance-1er-euro/">d’assurance au
                                1er euro.</a></p>

                        <p>Selon sa situation, un expatrié peut également souhaiter bénéficier d’une assurance qui le
                            protégera au-delà des risques médicaux. Les offres au 1er euro peuvent inclure, entre autres
                            :</p>

                        <ul>
                            <li>Une protection des bagages</li>
                            <li>Une assistance juridique</li>
                            <li>Une assurance responsabilité civile.</li>
                        </ul>

                        <p>Pour les expatriés prévoyants, la CFE propose une assurance retraite pour continuer à cotiser
                            à l’étranger.</p>

                        <h3>Garanties étendues et facilité de gestion du contrat</h3>

                        <p>Il est également important de <b>comparer les moyens de gestion du contrat</b> de sa future
                            assurance expatrié.</p>

                        <p>En souscrivant son assurance auprès d’une compagnie française, la langue n’est pas un
                            problème puisque le contrat est géré en français. En revanche, il convient de s’assurer que
                            l’accès à son dossier, à ses démarches ou à des conseillers peut se faire facilement de
                            partout et à tout moment. Cela peut être par le biais d’une application mobile ou d’un
                            espace en ligne dédié.</p>

                        <p>À noter : la souscription d’une complémentaire à la CFE implique souvent de gérer deux
                            contrats et de réaliser ses démarches auprès de deux organismes !</p>

                        <h2>Offres au 1er euro ou CFE : des remboursements et des cotisations qui ne se valent pas</h2>

                        <p>Les deux options principalement choisies par les expatriés pour leur assurance expatrié sont
                            soit les offres de la CFE, soit les assurances au 1er euro.</p>

                        <h3>Comparatif des remboursements : offres au 1er euro ou CFE et complémentaire ?</h3>

                        <p>Le <b>montant des remboursements pratiqués</b> est un autre point clé à comparer dans le
                            choix d'une assurance santé expatrié.</p>

                        <p>Cet élément joue généralement en défaveur de la CFE, car ses remboursements sont indexés sur
                            les barèmes et plafonds de la Sécurité sociale française. Ceux-ci sont généralement bien
                            inférieurs à la plupart des tarifs de soins pratiqués à l’étranger.</p>

                        <p>Dans ce cas, pour être mieux remboursé, il faut souscrire une complémentaire auprès d’une
                            autre compagnie. Une seconde cotisation s’ajoute alors à celle de la CFE et le prix global
                            de la couverture augmente : il faut en tenir compte au moment du comparatif.</p>

                        <p>Pour les remboursements, les offres au 1er euro sont souvent reconnues comme les plus
                            intéressantes. Les dépenses de santé sont, effectivement, prises en charge dès le premier
                            euro dépensé. De plus, les remboursements sont effectués sur le coût réel des soins
                            dispensés.</p>

                        <h3>Assurance expatrié : comparatif tarifaire</h3>

                        <p>Un élément primordial à regarder grâce au comparateur d'assurance expatrié est le <b>montant
                            des cotisations.</b></p>

                        <p>Les cotisations trimestrielles de la CFE sont calculées suivant l’âge et la situation
                            familiale de l’assuré : plus il est âgé, plus la cotisation est élevée.</p>

                        <p>Il faut ajouter à cette cotisation le prix de la complémentaire, variable suivant la
                            compagnie et les garanties choisies. Seul un devis permet de se faire une idée définitive du
                            coût de cette formule.</p>

                        <p>Le devis est également indispensable pour les assurances au 1er euro, dont le tarif évolue en
                            général avec l’étendue des garanties choisies et l’âge.</p>

                        <p>De nombreuses formules d’assurances au premier euro existent sur le marché pour s’adapter aux
                            besoins de chaque situation et à tous les budgets. Ces offres présentent donc souvent un
                            très bon rapport qualité-prix.</p>

                        <h2>Pourquoi utiliser un comparateur d’assurance pour expatrié ?</h2>

                        <p>Comparer les offres d’assurance internationale ne s’improvise pas. Il faut, en effet, être en
                            mesure :</p>

                        <ul>
                            <li>De lister les compagnies qui proposent ce type de contrats</li>
                            <li>D’estimer leur sérieux</li>
                            <li>D’analyser les offres de bases et leurs options</li>
                            <li>De bien connaître ses propres besoins et habitudes en matière de soins</li>
                            <li>De connaître les garanties indispensables selon son pays et sa durée d’expatriation.
                            </li>
                        </ul>

                        <p>Il convient ensuite de <b>contacter chaque compagnie pour obtenir un devis</b>.
                            Effectivement, les tarifs d’une assurance internationale varient beaucoup selon les
                            situations individuelles et l’étendue des garanties. Ces démarches sont répétitives et
                            prennent du temps.</p>

                        <p>Il faut également <b>être en mesure de comparer les devis reçus</b>, ce qui n’est pas
                            toujours évident, car il faut parfois procéder à des calculs et à des évaluations.</p>

                        <p>Les devis reçus ne sont pas toujours bien adaptés à la situation individuelle. Il devient
                            alors facile de s’y perdre et de se décourager, au point de faire un mauvais choix par
                            précipitation.</p>

                        <p>Faire le mauvais choix d’assurance pour son expatriation représente un risque financier, et
                            expose à une mauvaise prise en charge en cas de problème.</p>

                        <p><b>Utiliser un comparateur d’assurance expatrié</b> permet donc de <b>faire un tri</b> et
                            de <b>comparer efficacement les budgets, les conditions, les garanties et les
                                services</b> avec plus de simplicité.</p>

                        <img loading="lazy" width="1024" height="536"
                             src="https://expat-assurance.com/wp-content/uploads/2020/05/comparateur-assurance-internation-1024x536.png"
                             alt="Utiliser un comparateur pour choisir son assurance internationale" />

                        <h3>Questions fréquentes sur les assurances expatrié et leurs comparatifs</h3>

                        <p><b>CFE ou assurance au 1er euro : où se trouvent les meilleurs remboursements ?</b></p>

                        <p>La CFE rembourse en fonction du prix de la médecine en France, ce qui ne correspond pas
                            toujours aux coûts de la santé à l’étranger. Pour cette raison, la souscription à une
                            complémentaire est souvent indispensable. Cela rend la CFE bien moins avantageuse que
                            l’assurance au 1er euro en termes de coûts et remboursements.</p>

                        <p><b>À quels éléments faut-il faire attention en souscrivant à une assurance maladie ?</b>
                        </p>

                        <p>Voici une liste non exhaustive de quelques éléments auxquels faire attention lors du
                            choix d’une assurance santé :</p>

                        <ul>
                            <li>Les exclusions de garanties ou surprimes</li>
                            <li>La présence ou non du tiers-payant hospitalier</li>
                            <li>La présence ou non d’un questionnaire médical à l’inscription</li>
                            <li>La présence ou non d’une garantie rapatriement</li>
                            <li>La facilité de gestion du contrat.</li>
                        </ul>

                        <p><b>Comment souscrire à une assurance si je ne parle pas la langue du pays où je
                            m’expatrie ?</b></p>

                        <p>L’assurance santé internationale rend la gestion du contrat d’assurance très simple,
                            puisque les collaborateurs parlent français. La CFE est évidemment également accessible
                            en français, mais la gestion du dossier n’est pas toujours aisée depuis l’étranger.</p>

                    </div>

                </Container>


                {/*  MODALS PART  */}
                { this.renderModalContent() }

            </div>

        )

    }


}


export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data
    }),
    dispatch => ({
        onSaveDataFromMainPageForm: (main_form_data_object) => {
            dispatch(saveDataFromMainPageForm(main_form_data_object));
        },
        onClearInsurancePlansArrayBeforeFetching: () => {
            dispatch(clearInsurancePlansArrayBeforeFetching());
        }
    })
)(MainPage));

