import React from 'react';
import './App.css';
import Header from "./components/Header/Header";
import {initGA, initGTM} from "./global-app-elements/ga-functions";
import ReactGA from "react-ga";


// import InsuranceForm from "./demo-app/InsuranceForm";

// INITIALIZATION GOOGLE ANALYTICS
initGA();
ReactGA.initialize('G-Z5BGRBM9V5');
// initialization of Google Tag Manager
initGTM();



const App = ({children}) => (

    <div className="expatAssuranceApp">

        <Header />

        <div className="pageBody">

            {children}

        </div>

    </div>

);

export default App;
