import {
    SAVE_DATA_FROM_MAIN_PAGE_FORM
} from "../actions/actions";
import {isJson } from "../global-app-elements/global_helper_functions";
import moment from "moment";


// for testing
// let initialState = {
//     insurees_data: {
//         insuree_object: {
//             nationality: "Ukraine",
//             age: '28'
//         },
//         dependents_array: [
//             {
//                 nationality: "France",
//                 age: '16'
//             }
//         ],
//         destination_country: "États-Unis d’Amérique",
//         insurance_duration: '12+',
//         insuree_has_cfe: 'NO'
//     }
// };

let insurees_data = localStorage.getItem('insurees_data');

    insurees_data = JSON.parse(insurees_data);
if ( insurees_data ) {
    if (!insurees_data.hasOwnProperty("date_of_subscription")) {
        insurees_data["date_of_subscription"] = moment().toDate();
    }
}

let initialState;


if ( insurees_data ) {

    initialState = {
        insurees_data: insurees_data
    };

} else {

    initialState = {
        insurees_data: null
    };

}




export function main_form_reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_DATA_FROM_MAIN_PAGE_FORM :

            // SAVE MAIN FORM DATA TO LOCAL STORAGE
            localStorage.setItem('insurees_data', JSON.stringify(action.payload));

            return {
                ...state,
                insurees_data: action.payload
            };

        default:

            return state
    }
}
