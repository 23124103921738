import {
    SAVE_GATING_RESPONSE_FROM_API,
    CHANGE_SLIDE_IN_MODAL_VISIBILITY, CHANGE_SERVER_TIMEOUT_POPUP_VISIBILITY
} from "../actions/actions";



let initialState = {
    gating_response: null,
    slide_in_modal_visible: true,
    server_timeout: false
};




export function app_options_reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_GATING_RESPONSE_FROM_API :

            return {
                ...state,
                gating_response: action.payload
            };


        case CHANGE_SLIDE_IN_MODAL_VISIBILITY :

            return {
                ...state,
                slide_in_modal_visible: false
            };

        case CHANGE_SERVER_TIMEOUT_POPUP_VISIBILITY :

            return {
                ...state,
                server_timeout:  action.payload
            };

        default:

            return state

    }
}
