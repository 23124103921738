import {
    SAVE_INSURANCE_PLANS_FROM_API,
    CLEAR_INSURANCE_PLANS_ARRAY_BEFORE_FETCHING
} from "../actions/actions";



let initialState = {
    result_insurance_plans_array: null
};




export function insurance_plans_reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_INSURANCE_PLANS_FROM_API :

            return {
                ...state,
                result_insurance_plans_array: action.payload
            };

        case CLEAR_INSURANCE_PLANS_ARRAY_BEFORE_FETCHING :

            return {
                ...state,
                result_insurance_plans_array: null
            };

        default:

            return state
    }
}
