import {CHANGE_CURRENCY, HAS_THE_CURENCY_VALUE_CHANGED} from "../actions/actions";


let initialState = {
    currency: "USD",
    has_the_curency_value_changed: true
};


export function currency_options_reducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CURRENCY :

            return {
                ...state,
                currency: action.payload
            };

        case HAS_THE_CURENCY_VALUE_CHANGED :

            return {
                ...state,
                availability: action.payload
            };

        default:

            return state

    }
}
