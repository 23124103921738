import React, { Component } from 'react';
import './Header.css';
import {Container, Row, Col, Modal} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import expatLogo from '../../assets/images/expat-logo.svg';
import expatLogoNewColor from '../../assets/images/logo-new-color.svg';
import thankYouImg from "../../assets/images/thank-you.png";
import phoneClock from "../../assets/images/icons/phone-clock.svg"
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';
import {
    emailInputValidation, embedHubSpotInitScripts,
    textInputValidation
} from "../../global-app-elements/global_helper_functions";
import {
    changeSlideInModalVisibility,
    clearComparePlansServerArrayBeforeFetching,
    sendUserContactFormDataToEmail
} from "../../actions/actions";
import {GAevent, modalViewGA} from "../../global-app-elements/ga-functions";
import paulinaPhoto from "../../assets/images/flavie.png";
import {SLIDE_IN_MODAL_DELAY_IN_SECONDS} from "../../global-app-elements/global-constants";





class Header extends Component {


    constructor(props) {

        super(props);
        this.state = {
            user_personal_information: {
                contact_information_object: {
                    full_name: null,
                    email: null,
                    phone: null,
                    message: null
                }
            },
            form_validation_error_detected: false,
            is_modal_visible: false,
            modal_content_type: null,
            is_slide_in_modal_visible: false
        };

        this.handleGlobalContactForm = this.handleGlobalContactForm.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangeContactInformationFullName = this.handleChangeContactInformationFullName.bind(this);
        this.handleChangeContactInformationEmail = this.handleChangeContactInformationEmail.bind(this);
        this.handleChangeContactInformationPhone = this.handleChangeContactInformationPhone.bind(this);
        this.handleChangeContactInformationMessage = this.handleChangeContactInformationMessage.bind(this);
        this.handleEtreRappele = this.handleEtreRappele.bind(this);
        this.handleCloseSlideInModal = this.handleCloseSlideInModal.bind(this);
        this.handleShowSlideInModal = this.handleShowSlideInModal.bind(this);
    }



    componentDidUpdate(prevProps, prevState) {

        // Якщо користувач оновив Travel info data в Global Store то слід зробити новий запит до API
        if (prevProps.compare_plans_local_array !== this.props.compare_plans_local_array) {

            this.props.onClearComparePlansServerArrayBeforeFetching();

        }



        // код відповідає за показ Slide In Modal після загрузки результатів
        const gate_closed = localStorage.getItem('gate_closed');

        if ( this.props.result_insurance_plans_array && gate_closed === 'false' && this.props.slide_in_modal_visible ) {

            // помічаю в Global Store прапорець , щоб більше за сесію не відкривалося
            this.props.onChangeSlideInModalVisibility();

            let then = this;

            setTimeout(function () {

                then.handleShowSlideInModal();

            }, SLIDE_IN_MODAL_DELAY_IN_SECONDS );

        }



        // заборонити скролл body , якщо відкрита slide in modal
        if ( isMobile ) {

            if ( this.state.is_slide_in_modal_visible ) {

                document.body.style.overflow = 'hidden';

            } else {

                document.body.style.overflow = 'initial';

            }

        }

    }


    handleGlobalContactForm() {

        // TODO: Зробити толкову валідацію
        let not_valid_inputs_array = document.querySelectorAll('.contact-form-centered-modal .not-valid-input');


        if ( not_valid_inputs_array.length > 0 ) {

            this.setState({ form_validation_error_detected: true });

            not_valid_inputs_array[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        } else {

            let insurees_data = this.props.insurees_data;

            let data_for_sending = {
                user_personal_information: this.state.user_personal_information.contact_information_object,
                insurees_data: insurees_data
            }

            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', window.location.href]);
            _hsq.push(["identify",{
                firstname: 'converted in app',
                lastname: 'contact-us '  + data_for_sending.user_personal_information.full_name,
                email: data_for_sending.user_personal_information.email,
                phone: data_for_sending.user_personal_information.phone,
                commentaires: data_for_sending.user_personal_information.message ,
                dur_e_du_contrat_souhait_: data_for_sending.insurees_data.insurance_duration,
                adh_rent_cfe: data_for_sending.insurees_data.insuree_has_cfe,
                leadin_pays_de_destination_bc1e211f71cc3f2e8346f8719273fb80: data_for_sending.insurees_data.destination_country,
                leadin_pays_de_nationalitswb_0b819286eb1d5bab2234c31836f1e050: data_for_sending.insurees_data.insuree_object.nationality,
                user_age_from_search: data_for_sending.insurees_data.insuree_object.age,
                dependents_array: JSON.stringify(data_for_sending.insurees_data.dependents_array)
            }]);
            _hsq.push(['trackPageView']);

            this.props.onSendUserContactFormDataToEmail(data_for_sending);
            this.handleCloseModal();
            GAevent("contactFormSubmitted", "comparateur");
            this.handleShowModal('thank-you-form');

        }

    }


    handleEtreRappele() {

        this.handleShowModal('etre-rappele');

    }


    handleShowModal(content_type) {

        this.setState({
            is_modal_visible: true,
            modal_content_type: content_type
        });

        // TRIGGER MODAL VIEW FOR GOOGLE ANALYTICS
        modalViewGA(content_type);

    }


    handleCloseModal() {

        this.setState({
            is_modal_visible: false,
            modal_content_type: null
        });

    }


    handleChangeContactInformationFullName(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    full_name: input_value
                }
            }
        }));

    };


    handleChangeContactInformationEmail(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    email: input_value
                }
            }
        }));

    };


    handleChangeContactInformationPhone(changed_value) {

        let input_value = changed_value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    phone: input_value
                }
            }
        }));

    };


    handleChangeContactInformationMessage(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    message: input_value
                }
            }
        }));

    };


    handleCloseSlideInModal() {

        this.setState({
            is_slide_in_modal_visible: false
        });

    }


    handleShowSlideInModal() {

        this.setState({
            is_slide_in_modal_visible: true
        });

    }


    renderContactButtons() {

        return (

            <>

                <button onClick={()=>{window.open('tel:33176410734')}}
                        className="secondaryButtonStyle phoneButton phoneButton_desktop"
                >
                    <div className="phoneButton_desktop">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M16.9995 12.7278V15.287C17.0004 15.5245 16.9517 15.7597 16.8563 15.9774C16.7609 16.1951 16.6211 16.3905 16.4457 16.5511C16.2702 16.7117 16.0632 16.834 15.8376 16.9101C15.6121 16.9862 15.3732 17.0145 15.1361 16.9931C12.5059 16.7078 9.97937 15.8109 7.75957 14.3742C5.69433 13.0644 3.94337 11.3169 2.63104 9.2558C1.18648 7.03034 0.287505 4.49657 0.0069378 1.85976C-0.0144221 1.62386 0.0136689 1.3861 0.089422 1.16163C0.165175 0.937161 0.286931 0.730892 0.446937 0.555956C0.606943 0.38102 0.801693 0.241251 1.01879 0.145548C1.23589 0.0498457 1.47057 0.000305764 1.7079 8.27063e-05H4.27217C4.68699 -0.0039919 5.08913 0.142611 5.40366 0.412566C5.71818 0.682521 5.92362 1.05741 5.98168 1.46735C6.08991 2.28635 6.29063 3.09049 6.58001 3.86445C6.69501 4.16978 6.7199 4.50162 6.65173 4.82063C6.58355 5.13965 6.42518 5.43248 6.19537 5.66441L5.10983 6.7478C6.32662 8.88348 8.09844 10.6518 10.2384 11.8662L11.3239 10.7828C11.5563 10.5534 11.8497 10.3954 12.1694 10.3273C12.489 10.2593 12.8215 10.2841 13.1274 10.3989C13.9029 10.6877 14.7087 10.888 15.5293 10.9961C15.9445 11.0545 16.3237 11.2632 16.5948 11.5825C16.8658 11.9018 17.0099 12.3094 16.9995 12.7278Z" fill="#34D29C"/>
                                <path d="M14 0V6" stroke="#34D29C" stroke-width="2"/>
                                <path d="M11 3L17 3" stroke="#34D29C" stroke-width="2"/>
                            </svg>
                        </span> +33 (1) 76 41 07 34
                    </div>
                </button>

                <button
                    className="secondaryButtonStyle etreRappeleButton"
                    onClick={this.handleEtreRappele}>
                    <img src={phoneClock}/>
                    Être rappelé&middot;e <span className="icon-phone-2-fill"></span>
                </button>

                <button onClick={()=>{window.open('tel:33176410734')}}
                    className="secondaryButtonStyle phoneButton phoneButton_mobile"
                >
                    <div className="phoneButton_mobile">
                        <span className="icon-phone-2-fill"></span>
                    </div>
                </button>

                <button className={`secondaryButtonStyle headerContactFormButton ${(this.props.compare_plans_local_array.length > 0) ? 'is-visible' : null}`} onClick={this.handleShowModal.bind(this, 'contact-form')}>
                    <span className="icon-mail"></span>
                    <span className="headerContactFormButtonText">Contactez-nous</span>
                </button>

            </>

        )

    }


    renderModalContent() {

        let contact_information_object = this.state.user_personal_information.contact_information_object;

        switch ( this.state.modal_content_type ) {

            case 'etre-rappele':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="full-window-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                <button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}>
                                    <span className="icon-big-arrow-left"></span>
                                    <span className="close-modal-button-title">Retour</span>
                                </button>
                                <Modal.Title>Être rappelé&middot;e</Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body className="etreRappeleModalBody">
                            <Container className="defaultContainer">

                                <div className="etreRappeleModalText">
                                    On le sait, vous êtres très occupé·e, et nous aussi !
                                    Si vous préférez choisir la date et l'heure pour qu'on s'appelle,
                                    il vous suffit de le faire grâce au calendrier ci-dessous !
                                </div>

                                <div
                                    className="meetings-iframe-container"
                                    data-src="https://app.hubspot.com/meetings/pauline43/expat-assurance?embed=true"></div>

                                { embedHubSpotInitScripts() }

                            </Container>
                        </Modal.Body>
                    </Modal>

                );

            case 'contact-form':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        // backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="contact-form-centered-modal">
                        <Modal.Header closeButton>
                            <button className="close-modal" onClick={this.handleCloseModal}>
                                <span className="icon-close"></span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>

                            <div className={`userDataCard contactInformationCard ${(this.state.form_validation_error_detected) ? "form-validation-error" : null }`}>
                                <div className="userDataCardTitle"><span className="icon-message-circle"></span> Faisons un peu mieux connaissance</div>
                                <div className="userDataCardSubTitle">Remplissez le formulaire, et nous vous recontacterons sous 24 heures les jours ouvrés.</div>

                                <Row>

                                    <Col xs={12}>
                                        <div className={`inputBox ${(textInputValidation(contact_information_object.full_name)) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label>Nom et prénom<span className="red">*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Entrez les nom et prénom"
                                                defaultValue={contact_information_object.full_name}
                                                onChange={this.handleChangeContactInformationFullName}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs={6}>
                                        <div className={`inputBox ${(emailInputValidation(contact_information_object.email)) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label>Adresse email<span className="red">*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Entrez votre adresse e-mail"
                                                defaultValue={contact_information_object.email}
                                                onChange={this.handleChangeContactInformationEmail}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={6}>
                                        <div className={`inputBox ${(contact_information_object.phone) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label>Téléphone<span className="red">*</span></label>
                                            <PhoneInput
                                                country={'fr'}
                                                placeholder="+33 1 09 75 83 51"
                                                defaultValue={contact_information_object.phone}
                                                onChange={this.handleChangeContactInformationPhone}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs={12}>
                                        <div className={`inputBox ${(textInputValidation(contact_information_object.message)) ? "is-valid-input" : null }`}>
                                            <label>Message <span>(facultatif)</span></label>
                                            <textarea
                                                defaultValue={contact_information_object.message}
                                                onChange={this.handleChangeContactInformationMessage}
                                            ></textarea>
                                        </div>
                                    </Col>

                                </Row>

                                <Row className="bottomRow">
                                    <Col xs={9} className="legalCol">
                                        <p>
                                            Les données collectées par Expat International Care sont nécessaires afin de vous présenter des produits d’assurance adaptés à votre situation, et faciliter la souscription de votre contrat d’assurance. En cas d’accord de votre part, vos données sont également collectées à des fins de prospection commerciale. Les données suivies d’un (*) sont obligatoires. A défaut, nous ne pourrons valablement traiter votre demande. Pour en savoir plus sur le traitement de vos données et sur vos droits, consultez notre <a href="https://expat-assurance.com/fr/politique-protection-donnees/" target="_blank">Politique en matière de protection des données personnelles</a>.
                                        </p>
                                    </Col>

                                    <Col xs={3}>
                                        <button className="mainButtonStyle handleReceiveACall" onClick={this.handleGlobalContactForm}>
                                            Envoyer <span className="icon-big-arrow-right"></span>
                                        </button>
                                    </Col>

                                </Row>

                            </div>

                        </Modal.Body>
                    </Modal>

                );

            case 'thank-you-form':
                return (
                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        // backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="contact-form-centered-modal">
                        <Modal.Header closeButton>
                            <button className="close-modal" onClick={this.handleCloseModal}>
                                <span className="icon-close"></span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="thankYouPageBlock thankYouInsurancePage">
                                <div className="thankYouPageIcon"><img src={thankYouImg} alt="Expat Assurance"/></div>
                                <div className="thankYouPageTitle">Merci !</div>
                                <div className="thankYouPageDescription">
                                    Un de nos conseillers va vous recontacter sous 24 heures les jours ouvrés.
                                    Si vous préférez choisir vous-même l'heure de votre rendez-vous téléphonique, <button onClick={this.handleEtreRappele}>cliquez ici</button>
                                </div>
                                <div className="thankYouPageButton">
                                    <button onClick={this.handleCloseModal} className="mainButtonStyle">Retourner</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                );

            default:

                return null

        }

    }


    renderHeaderType() {

        switch (this.props.location.pathname) {
            case '/':
                return (

                    <div className="header mainPageHeaderStyle">

                        <Container className="headerContainer">

                            <Row>
                                <Col xs={12} className="expatLogoBlock">
                                    <a href="https://expat-assurance.com/?utm_source=ea_app" target="_blank">
                                        <img src={expatLogoNewColor} alt="Expat Assurance" />
                                    </a>
                                </Col>
                            </Row>

                        </Container>

                    </div>

                );

            case '/insurance':

                return (

                    <div className="header defaultHeaderStyle">

                        <Container className="headerContainer">

                            <Row>
                                <Col xs={3} className="appLogoBlock">
                                    <a href="https://expat-assurance.com/?utm_source=ea_app" target="_blank">
                                        <img src={expatLogoNewColor} alt="Expat Assurance" />
                                    </a>
                                </Col>
                                <Col xs={9} className="headerButtonsPart">
                                    { this.renderContactButtons() }
                                </Col>
                            </Row>

                        </Container>

                        {/*  MODALS PART  */}
                        { this.renderModalContent() }


                        {/*  SLIDE IN MODAL  */}
                        <div className={`slideInModalBlockOverlayForMobile ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}></div>

                        <div className={`slideInModalBlock ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}>
                            <div className="slideInModalBody">
                                <div className="slideInModalAvatar">
                                    <img src={paulinaPhoto} alt="Paulina"/>
                                </div>

                                <div className="slide-modal-animate-wrapper-1">
                                    <div className="slideInModalMessage">
                                        <p>
                                            Bonjour ! Moi c'est Flavie, et dans les 5 dernières années j'ai aidé plus de 400 familles à profiter de leur séjour à l'étranger en tout sécurité en les aidant à choisir une assurance qui leur convient
                                        </p>

                                        <p>
                                            On a remarqué que vous utilisiez le comparateur depuis quelque temps, et on sait que ça peut être dur de s'y retrouver. Le plus simple, c'est que nous nous appelions, et que je puisse vous aider à choisir l'offre qui vous convient.
                                        </p>

                                        <p>
                                            Si ça vous va, dites-moi comment je peux vous contacter :
                                        </p>
                                    </div>
                                </div>

                                <div className="slideInModalButtonsBlock">

                                    <div className="slide-modal-animate-wrapper-2">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonOne" onClick={this.handleEtreRappele}>
                                            <span className="slideInModalHandlerButtonBody">
                                                Choisir un horaire
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-3">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonTwo" onClick={this.handleShowModal.bind(this, 'contact-form')}>
                                            <span className="slideInModalHandlerButtonBody">
                                                Remplir le formulaire, et je vous recontacterai au plus vite !
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-4">
                                        <button className="slideInModalCloseButton" onClick={this.handleCloseSlideInModal}>
                                            <span className="slideInModalHandlerButtonBody">
                                                Je préfère choisir seul ma<br /> coassurance et mon déductible
                                            </span>
                                            <span className="icon-close"></span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                );

            default:
                return (

                    <div className="header defaultHeaderStyle">

                        <Container className="headerContainer">

                            <Row>
                                <Col xs={6} className="appLogoBlock">
                                    <a href="https://expat-assurance.com/?utm_source=ea_app" target="_blank">
                                        <img src={expatLogoNewColor} alt="Expat Assurance" />
                                    </a>
                                </Col>
                                <Col xs={6} className="headerButtonsPart">

                                    <button className="secondaryButtonStyle headerContactFormButton" onClick={this.handleShowModal.bind(this, 'contact-form')}>
                                        <span className="icon-mail"></span>
                                        <span className="headerContactFormButtonText">Contactez-nous</span>
                                    </button>

                                </Col>
                            </Row>

                        </Container>

                        {/*  MODALS PART  */}
                        { this.renderModalContent() }


                        {/*  SLIDE IN MODAL  */}
                        <div className={`slideInModalBlockOverlayForMobile ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}></div>

                        <div className={`slideInModalBlock ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}>
                            <div className="slideInModalBody">
                                <div className="slideInModalAvatar">
                                    <img src={paulinaPhoto} alt="Paulina"/>
                                </div>

                                <div className="slide-modal-animate-wrapper-1">
                                    <div className="slideInModalMessage">
                                        <p>
                                            Bonjour ! Moi c'est Pauline, et dans les 5 dernières années j'ai aidé plus de 400 familles à profiter de leur séjour à l'étranger en tout sécurité en les aidant à choisir une assurance qui leur convient
                                        </p>

                                        <p>
                                            On a remarqué que vous utilisiez le comparateur depuis quelque temps, et on sait que ça peut être dur de s'y retrouver. Le plus simple, c'est que nous nous appelions, et que je puisse vous aider à choisir l'offre qui vous convient.
                                        </p>

                                        <p>
                                            Si ça vous va, dites-moi comment je peux vous contacter :
                                        </p>
                                    </div>
                                </div>

                                <div className="slideInModalButtonsBlock">

                                    <div className="slide-modal-animate-wrapper-2">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonOne" onClick={this.handleEtreRappele}>
                                            <span className="slideInModalHandlerButtonBody">
                                                Choisir un horaire
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-3">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonTwo" onClick={this.handleShowModal.bind(this, 'contact-form')}>
                                            <span className="slideInModalHandlerButtonBody">
                                                Remplir le formulaire, et je vous recontacterai au plus vite !
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-4">
                                        <button className="slideInModalCloseButton" onClick={this.handleCloseSlideInModal}>
                                            <span className="slideInModalHandlerButtonBody">
                                                Je préfère choisir seul·e ma<br /> coassurance et mon déductible
                                            </span>
                                            <span className="icon-close"></span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                );
        }

    }



    render() {

        return (<>{ this.renderHeaderType() }</>)

    }


}


export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data,
        compare_plans_local_array: state.compare_plans_data.local_plans_data,
        result_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        slide_in_modal_visible: state.app_options.slide_in_modal_visible,
        gating_response: state.app_options.gating_response,
    }),
    dispatch => ({
        onSendUserContactFormDataToEmail: (data_for_sending) => {
            dispatch(sendUserContactFormDataToEmail(data_for_sending));
        },
        onClearComparePlansServerArrayBeforeFetching: () => {
            dispatch(clearComparePlansServerArrayBeforeFetching());
        },
        onChangeSlideInModalVisibility: () => {
            dispatch(changeSlideInModalVisibility());
        }
    })
)(Header));


