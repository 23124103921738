import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import store from './Store/Store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';



import MainPage from "./components/PagesBody/MainPage/MainPage";
import ResultPage from "./components/PagesBody/ResultPage/ResultPage";
import GetPlanPage from "./components/PagesBody/GetPlanPage/GetPlanPage";
import ThankYouPage from "./components/PagesBody/ThankYouPage/ThankYouPage";


ReactDOM.render(
  // <React.StrictMode>

      <Provider store={store}>
          <BrowserRouter> {/* basename="/quote"*/}

                  <App>
                      <Switch>
                          <Route exact path='/' component={MainPage}/>
                          <Route exact path='/insurance' component={ResultPage}/>
                          <Route exact path='/get-plan' component={GetPlanPage}/>
                          <Route exact path='/thank-you' component={ThankYouPage}/>
                      </Switch>
                  </App>

          </BrowserRouter>
      </Provider>,

  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
