import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

export const initGTM = () => {
    TagManager.initialize({
        gtmId: 'GTM-5MS93FJ'
    });
}

export const initGA = (trackingID) => {
    ReactGA.initialize("UA-96084156-1");
}


export const pageViewGA = (page) => {
    ReactGA.pageview(page);
}


export const modalViewGA = (modal) => {
    ReactGA.modalview(modal);
};


export const GAevent = (categoryName, eventName) => {
    ReactGA.event({
        category: categoryName,  // Required
        action: eventName,       // Required
        label: 'labelName',
        value: 10,
        nonInteraction: false
    });
}
